import { DownloadOutlined } from '@ant-design/icons';
import './CourseFinishedView.scss';
import { Button, Row, Typography, Space } from "antd";
import FeedbackView from '../../FeedbackView/FeedbackView';
import { useState } from 'react';
import axios from "axios";
import { getToken } from '../../../MSALExtensions';
import { AuthScopes } from '../../../AuthConfig';
import { publicClientApplication } from '../../..';



interface courseFinishedVars {
    studentName: string | undefined;
    courseName: string;
    dateCompleted: Date;
}

const CourseFinishedView: React.FC<courseFinishedVars> = ({ studentName, courseName, dateCompleted }): JSX.Element => {

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => { setIsOpen(true) };
    const closeModal = () => { setIsOpen(false) };

    const getCertificate = async () => {
        const accessToken = await getToken(publicClientApplication, AuthScopes.CertsApi);
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };
        const bodyData = {
            StudentName: studentName ?? 'New cert, who dis',
            CourseName: courseName,
            CompletionDate: dateCompleted
        };

        await axios.post(
            `${process.env.REACT_APP_ACADEMY_CERTS_API}/certificate/course`,
            bodyData,
            { headers, responseType: 'blob' } // blob stops wonky character encoding that breaks the pdf
        ).then((response) => {
            const pdf = new Blob([response.data]);
            const url = window.URL.createObjectURL(pdf);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${courseName}.pdf`;

            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            a.parentNode?.removeChild(a);
        }).catch((err) => console.log(err));
    };

    return (
        <Row className="course-finished-view">
            <Space className="course-finished-view-content" direction='vertical'>
                <Typography.Title>Congratulations! You have completed this course!</Typography.Title>
                <Typography.Paragraph>
                    You completed this course on <b>{dateCompleted.toLocaleString('en-GB', { timeZone: 'UTC' })}</b>
                </Typography.Paragraph>
                {
                    //<Button onClick={getCertificate}>Download Certificate</Button>
                }
                <Button block className="course-finish-btn feedback-btn" size="large" onClick={openModal}>Give Course Feedback</Button>
                <FeedbackView isOpen={isOpen} openModal={openModal} closeModal={closeModal}/>
            </Space>
        </Row>
    );
};

export default CourseFinishedView;