import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import './PageHeader.scss';
import { Row, Space, Typography } from "antd";
import parse from 'html-react-parser';
import { signInHandler } from '../../Utils/Helper/msalFunctions';

interface PageHeaderProps {
    title: string,
    bannerUrl: string,
    desc?: string | null
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, desc }): JSX.Element => {
    const { instance } = useMsal();
    
    return (<>
        <Space className="page-header row-padded">
            <Row className="page-header__content">
                <Typography.Title className="page-header__title">{title}</Typography.Title>
                {desc ? <>
                    <Typography.Paragraph className="page-header__description">
                        {parse(desc) || ''}
                    </Typography.Paragraph>
                    <UnauthenticatedTemplate>
                        <Typography.Link className="page-header__button" onClick={async () => await signInHandler(instance)}>Join the Academy</Typography.Link>
                    </UnauthenticatedTemplate>
                </> : <></>}
            </Row>
        </Space>
    </>);
};

export default PageHeader;