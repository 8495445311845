import './CourseView.scss';
import { Course, CourseContent } from '../../Utils/Types/CourseTypes';
import { Row, Col, Steps, Button, Typography, StepProps, Space, Tag, notification, Spin, message } from 'antd';
import { useState, useEffect, useRef } from 'react';
import CourseContentView from './CourseContentView/CourseContentView';
import axios from "axios";
import { getColorTag } from '../../Utils/Helper/displayFunctions';
import CourseFinishedView from './CourseFinishedView/CourseFinishedView';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import CourseLockedView from './CourseLockedView/CourseLockedView';
import { getToken } from '../../MSALExtensions';
import { publicClientApplication } from '../..';
import { AuthScopes } from '../../AuthConfig';
import { useMsal } from '@azure/msal-react';
import parse from 'html-react-parser';

interface courseVars {
  alias: string | undefined;
}

const CourseView: React.FC<courseVars> = ({ alias }): JSX.Element => {
  const { accounts } = useMsal();
  const [course, setCourse] = useState({} as Course);
  const [current, setCurrent] = useState(0);
  const [items, setItems] = useState([] as StepProps[]);
  const [canNext, setCanNext] = useState(true);
  const [quizIndex, setQuizIndex] = useState([] as number[]);
  const [api, contextHolder] = notification.useNotification();
  const [dateCompleted, setDateCompleted] = useState(new Date());

  const [isFinished, setIsFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // setCurrent actually gives previous hence the below states
  const [stepChanged, setStepChanged] = useState(false);
  //const [lastCurrent, setLastCurrent] = useState(0);
  const lastCurrent = useRef(0)


  const COURSE_QUERY = `{
      course (where: {alias: {alias: "${alias}"}}) {
        displayText
        contentItemId
        alias {
          alias
        }
        displayImage {
          paths
          urls
        }
        courseMetaDescription
        courseDescription {
          html
        }
        courseContent {
          contentItems {
            contentType
            ... on CourseQuiz {
              quizQuestions {
                contentItems {
                  contentItemId
                  ... on CourseQuizQuestion {
                    courseQuestion {
                      question
                    }
                    multiChoiceAnswers {
                      contentItems {
                        ... on QuizMultiChoiceOption {
                          quizMultiChoiceOption {
                            option
                            isCorrect
                          }
                          contentItemId
                          contentType
                        }
                        contentItemId
                        contentType
                      }
                    }
                    contentItemId
                    contentType
                    isMultipleAnswers
                  }
                  ... on CourseOpenEndedQuestion {
                    courseQuestion {
                      question
                      isOptional
                    }
                    contentItemId
                    contentType
                  }
                }
              }
              contentItemId
              contentType
              displayText
            }
            ... on CourseText {
              contentItemId
              contentType
              textBody {
                markdown
                html
              }
              displayText
            }
            ... on CourseVideo {
              displayText
              courseIframeContent {
                text
                url
              }
              contentItemId
            }
            ... on CourseHtmlContent {
              displayText
              contentType
              contentItemId
              htmlBody {
                html
              }
            }
            ... on CourseIframeContent {
              displayText
              contentType
              contentItemId
              sourceLink {
                url
                text
              }
            }
            ... on CourseCustomIframeContent {
              displayText
              contentType
              contentItemId
              htmlBody {
                html
              }
            }
          }
        }
        tags
        displayColor
      }
    }`

  const next = () => {
    setCurrent(current + 1);
    setStepChanged(true);
    lastCurrent.current = current + 1
  };

  const prev = () => {
    setCurrent(current - 1);
    setStepChanged(true);
    lastCurrent.current = current - 1
    if (!canNext) {
      setCanNext(true);
    }
  };

  const finishCourse = async () => {
    //setIsFinished(true);
    const accessToken = await getToken(publicClientApplication, AuthScopes.EventApi);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const toSubmit = {
      courseId: course.contentItemId,
      stepId: "Completed"
    }

    await axios.post(`${process.env.REACT_APP_ACADEMY_COURSES_API}/Course/Progress`, toSubmit, { headers })
      .then((response) => {
        if (response.status === 200) {
          setIsFinished(true);
        }
      })
      .catch((error) => {
        console.log(error);
        api['error']({
          message: 'Error in completing course.',
          description:
            'There is a problem recording your course completion. Please try again later.',
        });
      });
  }

  const setPersistedCurrentStep = async () => {
    const accessToken = await getToken(publicClientApplication, AuthScopes.CourseApi);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    
    const toSubmit = {
      courseId: course.contentItemId,
      stepId: (items[lastCurrent.current] as any).key
    }

    await axios.post(`${process.env.REACT_APP_ACADEMY_COURSES_API}/Course/Progress`, toSubmit, { headers })
      .then((response) => {
        if (response.status === 200) {
          message.success('Your course progress has been saved.');
        }
      })
      .catch((error) => {
        console.log(error);
        // TODO: block navigation
      });
  }

  const getPersistedCurrentStep = async (sItems: any[], courseId: string) => {
    const accessToken = await getToken(publicClientApplication, AuthScopes.CourseApi);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    axios.get(`${process.env.REACT_APP_ACADEMY_COURSES_API}/Course/Progress/${courseId}`, { headers })
      .then((response) => {
        if (response.status === 200 && response.data) {
          if (response.data.currentStepId && response.data.currentStepId !== 'Completed') {
            setCurrent(sItems.findIndex(i => i.key === response.data.currentStepId));
          }
          else if (response.data.currentStepId === 'Completed') {
            setIsFinished(true);
            setDateCompleted(response.data.dateCompleted);
          }
          else {
            // set first step as current
          }
        }
        else {
          // send error message
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }



  useEffect(() => {
    setIsLoading(true);
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
      headers: {
        'Content-Type': 'application/graphql'
      },
      data: COURSE_QUERY
    };

    axios(config).then(function (response: any) {
      const result = response.data.data.course;
      if (result) {
        setCourse(result[0])
        //setItems(result[0].courseContent.contentItems.map((item: CourseContent) => ({ key: item.displayText, title: item.displayText })))
        let qIndexes: any = [];
        let sItems: any = []
        result[0].courseContent.contentItems.map(function (c: CourseContent, i: number) {
          sItems.push({ key: c.contentItemId, title: c.displayText })
          if (c.contentType === "CourseQuiz") {
            qIndexes.push(i);
          }
        });
        setItems(sItems);
        setQuizIndex(qIndexes);
        getPersistedCurrentStep(sItems, result[0].contentItemId);
      }
      else {
        // TODO: no data front end
      }
    })
      .catch(function (error: any) {
        console.log(error);
      }).finally(() => {
        setIsLoading(false)
      });

  }, [])

  useEffect(() => {
    // TODO: remove if quiz is finished
    if (quizIndex?.includes(current)) {
      setCanNext(false);

    }
  }, [current])

  useEffect(() => {
    // TRIGGERS ON ROUTE LEAVE
    return () => {
      if (accounts.length > 0 && stepChanged) {
        setPersistedCurrentStep();
      }
    }
  }, [stepChanged])


  return (
    <>{
      isLoading ? <div className="course-spin-container"><Spin /></div> :
<>
        <Row gutter={{ xs: 40, lg: 20 }} className="course row-padded-end mx-auto">
          <Col className="course__description">
            <Typography.Title className="course__description-title">{course.displayText}</Typography.Title>
            <Space size={[0, 8]} className="course__description-tags">
              {course.tags?.map(tag =>
                <Tag color={getColorTag(tag)} className={`course__description-tag ${tag}`}>{tag}</Tag>
              )}
            </Space>
            <img className="course__description-image" src={`${process.env.REACT_APP_ORCHARD_API_URL}${course.displayImage?.urls[0]}`} alt={course.displayText} />
          </Col>
          </Row>
          <Row gutter={{ xs: 40, lg: 20 }} className="course row-padded-end mx-auto">
          <Col>
            <Typography.Paragraph>{parse(course.courseDescription?.html ?? "")}</Typography.Paragraph>
          </Col>
          </Row>
          <Row>
          <Col className="course__content">
            <AuthenticatedTemplate>
              {course.contentItemId ? (isFinished ? <CourseFinishedView studentName={accounts[0].name} courseName={course.displayText} dateCompleted={dateCompleted} /> : (
                <section className="steps-container">
                  <Steps current={current} items={items} />
                  <Row className="row-padded-y steps-container-body"><CourseContentView courseContent={course.courseContent.contentItems[current]} nextStep={next} setCanNext={setCanNext} /></Row>
                  <Row className="steps-row">
                    <Space size={8} wrap>
                      {current > 0 && (
                        <Button ghost onClick={() => prev()} className="steps-prev-btn">
                          Previous
                        </Button>
                      )}
                      {current < course.courseContent.contentItems.length - 1 && (
                        <Button type="primary" className="steps-next-btn" onClick={() => next()} disabled={!canNext}>
                          Next
                        </Button>
                      )}
                      {current === course.courseContent.contentItems.length - 1 && (
                        <Button type="primary" className="steps-finish-btn" onClick={() => finishCourse()} disabled={!canNext}>
                          Finish Course
                        </Button>
                      )}
                    </Space>
                  </Row>
                </section>
              )) : <div>No course</div>}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <CourseLockedView />
            </UnauthenticatedTemplate>
          </Col>
        </Row>
</>

    }
    </>
  );
};


export default CourseView;