import { createBrowserRouter, Navigate } from "react-router-dom";

import App from './App';
import ErrorPage from './Pages/ErrorPage/ErrorPage';
import CoursesPage from './Pages/CoursesPage';
import CoursePage from './Pages/CoursePage';
import ContactPage from './Pages/ContactPage';
import ResourcesPage from './Pages/ResourcesPage';
import HomePage from "./Pages/HomePage";
import EventPage from "./Pages/EventPage";
import ApiTest from "./Pages/ApiTest";
import EventsPage from "./Pages/EventsPage";
import AboutPage from "./Pages/AboutPage";
import PrivacyPage from "./Pages/PrivacyPage";
import RegistrationCompletePage from "./Pages/AuthRedirectPages/RegistrationCompletePage";
import LoginRedirectPage from "./Pages/AuthRedirectPages/LoginRedirectPage";
import ProfilePage from "./Pages/ProfilePage";
import AdminPanel from "./Components/AdminPanel/AdminPanel";
import AdminPanelPage from "./Pages/AdminPanelPage";

export const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorPage/>,
      children: [
        { index: true, element: <HomePage /> },
        {
            path: "home",
            element: <HomePage/>,
        },
        {
          path: "courses",
          element: <CoursesPage />,
        },
        {
          path: "courses/:alias",
          element: <CoursePage/>,
        },
        {
          path: "contact",
          element: <ContactPage/>
        },
        {
          path: "resources",
          element: <ResourcesPage />
        },
        {
          path: "events",
          element: <EventsPage />,
        },
        {
          path: "ashford",
          element: <Navigate replace to="/events/ashford"/> // temp ashford fix from launch transition
        },
        {
          path: "events/:alias",
          element: <EventPage />
        },
        {
          path: "about",
          element: <AboutPage />,
        },
        {
          path: "privacy",
          element: <PrivacyPage />,
        },
        {
          path: "registration-success",
          element: <RegistrationCompletePage />,
        },
        {
          path: "login-success",
          element: <LoginRedirectPage />,
        },
        {
          path: "profile",
          element: <ProfilePage />,
        },
        {
          path: 'apiTest',
          element: <ApiTest />
        },
        {
          path: 'admin-panel',
          element: <AdminPanelPage />
        },
      ],
    },
  ]);