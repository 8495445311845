import './EventView.scss';
import { useEffect, useState, createRef, ReactNode } from "react";
import axios from "axios";
import { Event } from "../../Utils/Types/EventTypes";
import { Row, Col, Typography, Form, notification, Button } from "antd";
import EventFAQView from './EventFAQView/EventFAQView';
import { getReadableColour, getTextColor } from '../../Utils/Helper/displayFunctions';
import EventRSVPView from './EventRSVPView/EventRSVPView';
import EventGMapView from './EventGMapView/EventGMapView';
import ReCAPTCHA from 'react-google-recaptcha';
import EventDetailSectionView from './EventDetailSectionView/EventDetailSectionView';

interface Props {
  children?: ReactNode,
  className?: string,
  href?: string,
  style: any,
  // any props that come into the component
}

const NLink= ({ children, ...props }: Props) => (
  <a {...props}>{children}</a>
);

interface EventVars {
    alias: string | undefined;
}

const EventView: React.FC<EventVars> = ({ alias }): JSX.Element => {
    const [event, setEvent] = useState({} as Event);
    const [form] = Form.useForm();
    const [isCaptcha, setIsCaptcha] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    const recaptchaRef = createRef<ReCAPTCHA>();
    const recaptchaSiteKey = '6LcEDZMmAAAAAHsGe-r_TCOk-olTigF8B1-s6GBJ';

    const captchaPassed = (value: string | null) => {
        if (value)
        {
            setIsCaptcha(true);
        }
        else {
            setIsCaptcha(false);
        }
    }


    const EVENT_QUERY = `{
      event(where: {alias: {alias: "${ alias }"}}) {
        contentItemId
        displayText
        colorTheme
        displayImage {
          urls
          paths
        }
        brandImage {
          urls
          paths
        }
        ascBrandImages {
          paths
          urls
        }
        useExternalLink
        externalSignUpLink {
          text
          url
        }
        startDate
        endDate
        eventVenue {
          address1
          address2
          city
          country
          displayName
          postcode
          state
        }
        faqSection {
          contentItems {
            contentItemId
            ... on faq {
              contentItemId
              question
              answer {
                html
              }
            }
          }
        }
        eventStaff {
          contentItems {
            ... on EventStaff {
              contentItemId
              name
              image {
                paths
                urls
              }
              description
            }
          }
        }
      }
    }`;

    useEffect(() => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: { 
              'Content-Type': 'application/graphql'
            },
            data : EVENT_QUERY
        };
          
        axios(config).then(function (response: any) {
            const result = response.data.data.event;
            if (result) {
                setEvent(result[0])
            }
            else {
                // TODO: no data front end
            }
        })
        .catch(function (error: any) {
           console.log(error);
        });
    }, [  ]);

    return (<>
        {event.contentItemId ?
        (
          <>
          <Row className='page-header row-padded' style={{
            background: event.displayImage?.urls ? `linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url('${process.env.REACT_APP_ORCHARD_API_URL}${event.displayImage.urls}')` : 'white',
            backgroundSize: 'cover',
          }}>
            <Typography.Title className="event-banner-title" level={1} style={{color: 'white'}}>{event.displayText}</Typography.Title>
            <Row className="event-details">
              <Col className="event-details__left">
                <Row className="event-details__left--container">
                  <Col className="event-details__date" style={{color: getReadableColour(event.colorTheme, false) || 'white'}}>
                    {event.startDate ? new Date(event.startDate).getDate() + " " + month[new Date(event.startDate).getMonth()] + "\n" : ''}
                    <br />
                    {event.startDate ? new Date(event.startDate).getFullYear() : ''}
                  </Col>
                  <Col className="event-detail__meta-detail event-detail__meta-detail--book-now">
                    {event.eventStaff?.contentItems?.length > 0 && <Typography.Paragraph className="event-detail__event-name">Speakers: {event.eventStaff.contentItems.map(function(staff) {return staff.name})}</Typography.Paragraph>}
                    <NLink className="page-header__button" href={event.useExternalLink ? event.externalSignUpLink.url : "#event-form"} style={{ "--colorTheme": event.colorTheme || '#CAD23F', '--colorTheme-hoverText': getTextColor(event.colorTheme)}}>Book now</NLink>
                  </Col>
                </Row>
              </Col>
              <Col className="event-detail__meta-detail event-detail__meta-detail--address">
                  <Typography.Paragraph className="event-detail__address">
                      {event.eventVenue.displayName ? event.eventVenue.displayName + "\n" : ''}
                      {event.eventVenue.address1 ? event.eventVenue.address1 + "\n" : ''}
                      {event.eventVenue.address2 ? event.eventVenue.address2 + "\n" : ''}
                      {event.eventVenue.state ? event.eventVenue.state + "\n" : ''}
                      {event.eventVenue.city ? event.eventVenue.city + "\n" : ''}
                      {event.eventVenue.postcode ? event.eventVenue.postcode + ", " : ''}
                      {event.eventVenue.country ? event.eventVenue.country : ''}
                  </Typography.Paragraph>
              </Col>
            </Row>
            {event.brandImage?.urls?.length > 0 &&
              <Row className="event-sponsors">
                <Col className="event-sponsors__container">
                  <span>Sponsored by</span>
                  <span className="event-sponsors__container--logos">{
                    event.brandImage?.urls?.map((brandImgUrl: string) => {return <img src={`${process.env.REACT_APP_ORCHARD_API_URL}${brandImgUrl}`}/>})
                  }
                  {
                    event.brandImage?.urls?.length > 0 && event.ascBrandImages?.urls?.map((ascBrandImgUrl: string) => {return <img src={`${process.env.REACT_APP_ORCHARD_API_URL}${ascBrandImgUrl}`}/>})
                  }</span>
                </Col>
              </Row>
            }
        </Row>
        <div className="event-content" id="event-form">
          <Row className="row-padded-end event-directory-row" gutter={36}>
            <Col xs={24} sm={24} md={12}>
              <Row className="event-row rsvp">
                <EventRSVPView event={event} displayText={event.displayText} eventTime={event.startDate} colorTheme={event.colorTheme} alias={alias || ""}/> 
              </Row>
            </Col>
            <Col xs={24} sm={24} md={12} className="contact-location">
              <div className="content-map">
                <EventGMapView eventId={event.contentItemId} />
              </div>
              <Typography.Title level={2} className="event-section-title"
                style={{
                  color: getReadableColour(event.colorTheme, true) || '#78880A',
                }}
              >
                Event Location
              </Typography.Title>
              <Typography.Paragraph>
                {event.eventVenue.displayName ? event.eventVenue.displayName + ", " : ''}
                {event.eventVenue.address1 ? event.eventVenue.address1 + ", " : ''}
                {event.eventVenue.address2 ? event.eventVenue.address2 + ", " : ''}
                {event.eventVenue.state ? event.eventVenue.state + ", " : ''}
                {event.eventVenue.city ? event.eventVenue.city + ", " : ''}
                {event.eventVenue.postcode ? event.eventVenue.postcode + ", " : ''}
                {event.eventVenue.country ? event.eventVenue.country : ''}
              </Typography.Paragraph>
            </Col>
          </Row>
          <EventDetailSectionView alias={alias} />
          {event.faqSection?.contentItems?.length > 0 &&
            <EventFAQView contentItems={event.faqSection.contentItems} colorTheme={event.colorTheme || '#78880A'}/>
          }
        </div>
        </>
        ) : ''
}
</>
)
}


export default EventView;