import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './Testimonials.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { Testimonial } from '../../Utils/Types/TestimonialTypes';

const TESTIMONIALS_QUERY = `{
    testimonial {
        contentItemId
        testimonialAuthor
        message
        occupation
    }
  }
  `;

/* TODO: Bug happens on only one testimonial on Ant Slider */

const Testimonials: React.FC = (): JSX.Element => {
    const [testimonialsData, setTestimonialsData] = useState([] as Testimonial[]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    useEffect(() => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: {
                'Content-Type': 'application/graphql'
            },
            data: TESTIMONIALS_QUERY
        };

        axios(config).then(function (response) {
            const result = response.data.data.testimonial;
            if (result) {
                setTestimonialsData(result);
            }
            else {
                // TODO: no data front end
            }
        })
            .catch(function (error) {
                console.log(error);
            }).finally(() => {
                // finally
                console.log(testimonialsData);
            });

    }, []);

    return (
        <div className="row-padded-end testimonials">
            <Slider {...settings}>
                {testimonialsData.map((testimonial, index) => (
                    <blockquote key={index} className="testimonials__quote">
                        <div className="testimonials__quote-wrapper">
                            <p className="testimonials__quote-message">{testimonial.message}</p>
                            <footer className="testimonials__quote-author">{testimonial.testimonialAuthor}<cite>, {testimonial.occupation}</cite></footer>
                        </div>
                    </blockquote>
                ))}
            </Slider>
        </div>
    );
}

export default Testimonials;
